
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'test',
  props: {
   current:{
	   type: String,
	   default: "home"
   }
  },
});
