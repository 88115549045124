<template>
	<div class="loginBox">
		<div class="loginTitle">紫菲通行证登录</div>
		<div class="loginDiv">
			<input type="text" v-model="mobile" class="userInput" placeholder="请输入手机号码" />
			<!-- <van-field
				class="userInput"
			    v-model="mobile"
			    :error="false"
				pattern="/^1[0123456789]\d{10}$/"
				trigger="onBlur"
				error-message=""
			    placeholder="请输入紫菲账号"
			  /> -->
		</div>
		<div class="loginDiv">
			<input type="text" class="imgCodeInput" v-model="code" placeholder="图片验证码" />
			<img :src="imgCode" class="imgCode" @click="refreshImgCode" />
		</div>
		<div class="loginDiv">
			<input type="text" v-model="smscode" class="phoneCodeInput" placeholder="手机验证码" />
			<van-button class="codeBtn" @click="getPhoneCode" v-preventReClick="1500">{{codeDes}}</van-button>
		</div>
		<van-button class="qdBtn" @click="phoneLoginFun" v-preventReClick="1500">确定</van-button>
		<div class="loginbtns">
			<router-link to='/pswLogin' replace>密码登录</router-link>
			<router-link :to='{path:"/forgetpsw",query:{history:"/phoneLogin"}}' replace>忘记密码</router-link>
			<router-link :to='{path:"/register",query:{history:"/phoneLogin"}}' replace>账号注册</router-link>
		</div>
	</div>
	<!--底部菜单-->
	<MENUE current='login'></MENUE>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import request from '@/service/request'
	import {Field,Toast,Button} from "vant"
	import MENUE from '@/components/menue/menue.vue';
	
	export default defineComponent({
		name: 'phoneLogin',
		components: {
			[Field.name]:Field,
			[Button.name]:Button,
			 MENUE
		},
		data() {
			return {
				mobile: '',
				imgCode:'',
				smscode:'',
				code:'',
				key:'',
				codeDes:"获取验证码"
			}
		},
		created(){
			this.getImgCode();
		},
		// directives:{
		// 	preventReClick:preventReClick
		// },
		methods:{
			async getImgCode(){
				debugger;
				this.imgCode="";
				var key=localStorage.getItem('key');
				if(key){
					this.key=key;
				}else{
					const {data}=await request.apiAxios({
						method:'get',
						url:request.GETKEY					
					});
					this.key=data.key;
					localStorage.setItem('key',data.key);
				}
				setTimeout(()=>{
					this.imgCode=request.baseURL+request.WEBCODE+"&key="+this.key;
				},0)
				
			},
			phoneLoginFun(el){
				console.log(el,"kk",el.disabled)
				if(!this.mobile||!this.smscode||!this.code){
					Toast("请完善信息！");
					return;
				}
				var phoneReg=/^(1[0123456789]{10})$/;
				if(phoneReg.test(this.mobile)){
					request.apiAxios({
						method: 'post',
						url: request.SMSLOGIN,
						data: {
							mobile:this.mobile,
							smscode:this.smscode,
							code:this.code,
							key:this.key
						}
					}).then((res) => {
						if(res.status){
							localStorage.setItem('token',res.data.token);
							localStorage.setItem('userInfo',JSON.stringify(res.data));
							this.$store.dispatch('setToken',res.token);
							Toast("登录成功！");
							this.$router.replace('/');
						}else{
							Toast(res.message);
							this.refreshImgCode();
						}
						
					}).catch((err) => {
						console.log(err);
						debugger;
					})
				}else{
					Toast("请输入正确的手机号码！");
				}
			},
			//刷新验证码
			refreshImgCode(){				
				this.getImgCode();
				
			},
			//获取验证码
			getPhoneCode() {
				if(this.codeDes!="获取验证码") return;
				if(!this.mobile){
					Toast("请输入手机号");
					return;
				}
				var phoneReg=/^(1[0123456789]{10})$/;
				if(phoneReg.test(this.mobile)){
					request.apiAxios({
						method: 'post',
						url: request.CODEOTHER,
						data: {
							mobile:this.mobile
						}
					}).then((res) => {
						if(res.status){
							var len=300;
							Toast.success("发送成功");
							this.timeDes(len);
						}else{
							Toast(res.message)
						}
						
					}).catch((err) => {
						console.log(err);
						debugger;
					})
				}else{
					Toast("请输入正确的手机号码");
				}
			},
			timeDes(len){
				if(len<=0){
					this.codeDes="获取验证码"
				}else{
					console.log(len);
					this.timer=setTimeout(()=>{
						len--;
						this.codeDes=len+'s';
						this.timeDes(len)
					},1000)
					
				}
			}
		},
		beforeUnmount() {
		  clearInterval(this.timer);
		}
	});
</script>

<style lang="less">
	
	
</style>
